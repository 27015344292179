<template>
  <div ref="echart" class="pie-wrap" :style="{'width': width, 'height': height}"></div>
</template>
<script>
import echarts from '@/plugin/echarts';
export default {
  name: 'charts_pie',
  props: {
    width: { type: String, default: '300px' },
    height: { type: String, default: '200px' },
    progress: {
      type: Object,
      function() {
        return {
          ycxrs: 0,
          ncxrs: 0,
        };
      },
    },
  },
  data() {
    return {
      echart: null,
    };
  },
  methods: {
    init() {
      this.echart = echarts.init(this.$refs.echart);
    },
  },
  mounted() {
    this.$nextTick().then(() => {
      this.init();
      this.echart.setOption({
        tooltip: {
          trigger: 'item',
        },
        legend: {
          top: '0',
          left: 'center',
          data: ['已抽选人数', '剩余选人数'],
        },
        series: [
          {
            name: '随机抽选进度',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '14',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 0, name: '已抽选人数' },
              { value: 1, name: '剩余选人数' },
            ],
            color: ['#01609B', '#93D1F5'],
          },
        ],
      });
    });
  },
  watch: {
    progress: {
      handler: function (newvalue) {
        let that = this;
        if (newvalue && newvalue.ycxrs && this.echart) {
          that.$nextTick(() => {
            let data = that.progress;
            that.echart.setOption({
              tooltip: {
                trigger: 'item',
              },
              legend: {
                top: '0',
                left: 'center',
                data: ['已抽选人数', '剩余选人数'],
              },
              series: [
                {
                  name: '随机抽选进度',
                  type: 'pie',
                  radius: ['50%', '70%'],
                  avoidLabelOverlap: false,
                  label: {
                    show: false,
                  },
                  emphasis: {
                    label: {
                      show: true,
                      fontSize: '14',
                      fontWeight: 'bold',
                    },
                  },
                  labelLine: {
                    show: false,
                  },
                  data: [
                    { value: data.ycxrs, name: '已抽选人数' },
                    { value: data.ncxrs - data.ycxrs, name: '剩余选人数' },
                  ],
                  color: ['#01609B', '#93D1F5'],
                },
              ],
            });
          });
        }
      },
      immediate: true,
    },
  },
};
</script>
<style scoped lang="scss">
</style>
