<template>
  <section class="component-onProgress">
    <div class="info">
      <div class="circle">
        <el-progress :percentage="progress.status === 'finish'?100:progress.progress" type="circle"></el-progress>
      </div>
      <div class="main" v-show="progress && progress.current && progress.current.xm">
        <div class="item">
          <div class="label">姓名：</div>
          <div class="data" :class="loading?'rotate':''">{{ progress && progress.current && progress.current.xm }}</div>
        </div>
        <div class="item">
          <div class="label">性别：</div>
          <div class="data" :class="loading?'rotate':''">{{ progress && progress.current && progress.current.xb }}</div>
        </div>
        <div class="item">
          <div class="label">身份证号码：</div>
          <div class="data" :class="loading?'rotate':''">{{ progress && progress.current && progress.current.sfzhm }}
          </div>
        </div>
        <div class="item">
          <div class="label">常居住地：</div>
          <div class="data" :class="loading?'rotate':''">{{ progress && progress.current && progress.current.cjzd }}
          </div>
        </div>
      </div>
    </div>
    <div class="btn">
      <el-button type="primary" size="small" v-if="progress && progress.status === 'finish'" @click="stop">查看结果
      </el-button>
    </div>
  </section>
</template>

<script>
import Candidate from '@/api/choose/candidate';

export default {
  // 进行中
  name: 'onProgress',
  props: {
    progress: {
      type: Object,
    },
  },
  data() {
    return {
      timeout: '',
      loading: true,
    };
  },
  methods: {
    stop() {
      this.$emit('stop', this.progress.items);
    },
    update(isFirst) {
      this.loading = true;
      Candidate.progress()
          .then((res) => {
            if (!res && !isFirst) {
              clearInterval(this.timeout);
              this.timeout = '';
              return;
            }
            this.$emit('changeProgress', res);
            if (res.status === 'finish') {
              Candidate.randomStatus().then((res) => {
                clearInterval(this.timeout);
                this.$emit('setStatus', res);

                this.$message.closeAll();
                this.$message.success('抽选完成');
                this.timeout = '';
              });
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
  mounted() {
  },
  created() {
    this.update(true);
    this.timeout = setInterval(this.update, 2000);
  },
  watch: {},
  beforeDestroy() {
    clearInterval(this.timeout);
  },
};
</script>

<style scoped lang="scss">
.component-onProgress {
  display: flex;
  align-items: center;
  flex-direction: column;

  .info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .circle {
      text-align: center;
      flex: 1;
    }

    .main {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding-bottom: 50px;

      .item {
        font-weight: bold;
        display: flex;
        padding-top: 20px;

        .data {
          color: #337fae;
        }
      }
    }
  }
}

.rotate {
  animation: d3 0.5s infinite linear;
}

@keyframes d3 {
  100% {
    transform: rotateX(360deg) rotateX(-720deg);
  }
}
</style>
