<template>
  <el-dialog
      :width="componentName!=='result'?'730px':'80%'"
      append-to-body
      title="随机抽取"
      :visible.sync="show"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      class="randomSelect-component"
      :class="componentName!=='result'?'':'wider'"
      :before-close="beforeClose"
      lock-scroll
  >
    <div class="main">
      <div class="item">
        <div class="label">选任单位</div>
        <div class="data">{{ data.xrdwmc }}</div>
      </div>
      <div class="item">
        <div class="label">年度</div>
        <div class="data">{{ data.nd }}</div>
      </div>
      <div class="item">
        <div class="label">选任标题</div>
        <div class="data">{{ data.jhmc }}</div>
      </div>
      <div class="item">
        <div class="label">计划人数</div>
        <div class="data blue">{{ data.sjcxrs }}</div>
      </div>

      <div class="item">
        <div class="label">计划时间</div>
        <div class="data">{{ data.bmkssj }} 至 {{ data.bmjssj }}</div>
      </div>
      <div class="item">
        <div class="label">常住居民总人数</div>
        <div class="data blue">{{ data.czjmzrs }}</div>
      </div>
      <div class="item">
        <div class="label">已抽取人数</div>
        <div
            class="data blue"
        >{{
            progress && progress.status != 'finish' && progress.ycxrs ? parseInt(beforeNum) + progress.ycxrs : data.ycxrs
          }}
        </div>
      </div>
      <div class="item">
        <div class="label">可抽取人数</div>
        <div
            class="data blue"
        >{{
            progress && progress.status != 'finish' && progress.ycxrs ? parseInt(beforeTotal) - progress.ycxrs : data.kcxrs
          }}
        </div>
      </div>
      <div class="item">
        <div class="label">拟抽选人数</div>
        <el-input v-model="query.ncxrs" placeholder="请输入拟抽选人数" @input="input" size="mini"
                  :disabled="disabled"></el-input>
      </div>
    </div>
    <div class="select">
      <component
          :is="componentName"
          :progress="progress"
          @start="start"
          @stop="stop"
          @changeProgress="changeProgress"
          @changeComponent="changeComponent"
          @setStatus="setStatus"
          ref="component"
          v-loading="loading"
          :element-loading-text="loadingTitle"
      ></component>
    </div>
  </el-dialog>
</template>

<script>
import pie from '@/components/charts/pie';
import category from '@/components/charts/category';
import ready from './component/ready';
import onProgress from './component/onProgress';
import result from './component/result';

import Candidate from '@/api/choose/candidate';
import numberToString from "@/plugin/numberToString";

export default {
  // 随机抽取
  name: 'checkCode',
  props: {
    title: {
      type: String,
    },
    tableData: {
      type: Array
    }
  },
  components: {
    pie,
    category,
    ready,
    onProgress,
    result,
  },
  data() {
    return {
      show: false,
      componentName: '',
      data: {},
      table: {},
      query: {
        ncxrs: '',
      },
      progress: {},
      disabled: false,
      loading: false,
      loadingTitle: '',
      beforeNum: 0,
      beforeTotal: 0
    };
  },
  methods: {
    input() {
      this.query.ncxrs = this.query.ncxrs.replace(/[^0-9]/g, '');
    },
    beforeClose(done) {
      if (this.progress.progress === 100) {
        Candidate.result();
      }
      if (done instanceof Function) {
        done();
        this.$emit('getList');
        this.$emit('changeShow', false);
      }
    },
    changeComponent(name,items=null) {
      if (name !== 'ready') {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
      this.componentName = name;
      if (name === 'result') {
        this.$emit('getList');
          let data = numberToString(items, 'sczt', this.table.options.sczt.children);
          data = numberToString(data, 'bmfs', this.table.options.bmfs.children);
        this.$nextTick(() => {
          this.$refs.component.init(data);
        });
        // Candidate.list({
        //   keyword: '',
        //   page: 1,
        //   size: 9999,
        //   bmfs: 1,
        // }).then((res) => {
        //   console.log('res', res)
        //   let {sczt, bmfs} = this.$store.getters.dic;
        //   console.log(sczt, bmfs);
        //   this.table.options = {sczt, bmfs};
        //   let data = numberToString(res.items, 'sczt', this.table.options.sczt.children);
        //   data = numberToString(data, 'bmfs', this.table.options.bmfs.children);
        //   console.log('列表数据', data)
        //   this.$nextTick(() => {
        //     this.$refs.component.init(data);
        //   });
        //
        // })
      }
    },
    changeProgress(data) {
      console.log(111);
      console.log(data);
      console.log(111);
      this.progress = data;
      console.log('create', this.data.ycxrs, this.progress.ycxrs)
    },
    toggle(show, data) {
      this.show = show;
      console.log(data);
    },
    // 开始抽选
    start() {
      if (!this.query.ncxrs) {
        this.$alert('请输入拟抽选人数');
        return;
      }
      if (this.query.ncxrs === 0) {
        this.$alert('请输入正确拟抽选人数');
        return;
      }
      if (this.query.ncxrs > this.data.kcxrs) {
        this.$alert(`当前可抽取的总人数为--${this.data.kcxrs}`);
        return;
      }
      this.loading = true;
      this.loadingTitle = '正在准备抽选';
      Candidate.start({ncxrs: this.query.ncxrs})
          .then(() => {
            this.$message.success('开始抽选');
            this.changeComponent('onProgress');
          })
          .finally(() => {
            this.loading = false;
          });
    },
    // 抽选结束
    stop() {
      Candidate.result().then((res) => {
        this.$emit('getList');
        this.changeComponent('result',res.items);
        window.localStorage.removeItem('beforNum')
        window.localStorage.removeItem('kcxrs')
      });
    },
    setStatus(data) {
      console.log('setStatus', data)
      Object.assign(this.data, data);
      window.localStorage.removeItem('beforNum')
      window.localStorage.removeItem('kcxrs')
    },
  },
  mounted() {
  },
  created() {
    let {sczt, bmfs} = this.$store.getters.dic;
    console.log(sczt, bmfs);
    this.table.options = {sczt, bmfs};

    console.log('create', this.data.ycxrs, this.progress.ycxrs)
    // 判断是否有进程 在抽选，如果有，或者没有查看结果的话就展示
    Promise.all([Candidate.randomStatus(), Candidate.progress()]).then((res) => {
      // console.log('this.beforeNum', this.beforeNum)
      if (!window.localStorage.getItem('beforNum')) {
        window.localStorage.setItem('beforNum', res[0].ycxrs)
      }

      if (!window.localStorage.getItem('kcxrs')) {
        window.localStorage.setItem('kcxrs', res[0].kcxrs)
      }
      console.log('create', res)
      this.beforeNum = window.localStorage.getItem('beforNum')
      this.beforeTotal = window.localStorage.getItem('kcxrs')
      Object.assign(this.data, res[0]);
      this.data = res[0];
      if (res[1]) {
        this.changeProgress(res[1]);
        console.log(res[1]);
        this.query.ncxrs = res[1].ncxrs;
        this.changeComponent('onProgress');
      } else {
        this.changeComponent('ready');
      }
    });
  },
  computed: {},
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__title {
  font-weight: bold;
}

.el-dialog {
  min-width: 650px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .main {
    font-weight: bold;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;

    .item {
      width: 50%;
      display: flex;
      align-items: center;
      padding-bottom: 15px;
      box-sizing: border-box;

      .label {
        width: 100px;
        text-align: right;
        padding-right: 20px;
      }

      .data {
        flex: 1;
        // padding-left: 10px;
      }

      .blue {
        color: #337fae;
      }

      .el-input {
        width: 50%;
      }

      .el-select {
        height: 100%;
        width: 50%;
      }
    }

    .item:nth-child(odd) {
      .data {
        padding-left: 10px;
      }
    }
  }

  .select {
    height: 100%;
  }
}

.wider {
  ::v-deep .el-dialog {
    margin: 5vh auto 0 auto !important;
  }

  ::v-deep .el-dialog__body {
    display: flex;
    flex-direction: column;
    height: 80vh;
  }
}
</style>
