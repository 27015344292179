import { tongke } from '@/axios'
// 候选人
class Candidate {
  /**
   * @functionName list
   * @param { Object }  params
   * @param { Number }  params.page
   * @param { Number }  params.size
   * 非必填
   * @param { Number }  params.nd     年度
   * @param { Number }  params.bmfs   报名方式  1-随机抽选|2-个人申请|3-组织推荐
   * @param { Number }  params.sczt   审查状态，多个状态用英文逗号隔开
   * @param { Number }  params.fpdw   分配单位ID，n为未分配
   * @param { Number }  params.keyword  关键词
   * @Description   // 候选人列表
   * @return { Object }
   */
  list(params) {
    return tongke.get('/quxian/hxr', { params })
  }
  // 候选人详情-----传入身份证号码
  detail(sfzhm) {
    return tongke.get(`/quxian/hxr/${sfzhm}`)
  }
  // 随机抽选信息
  randomStatus() {
    return tongke.get('/quxian/sjcxhxr/info')
  }
  // 随机抽选开始
  //  params.ncxrs 	拟抽选人数
  start(params) {
    return tongke.get('/quxian/sjcxhxr/start', { params })
  }
  // 随机抽选进度
  progress() {
    return tongke.get('/quxian/sjcxhxr/progress')
  }
  // 随机抽选结果
  result() {
    return tongke.get('/quxian/sjcxhxr/finish')
  }
  // 分配列表
  distributeList() {
    return tongke.get('/quxian/fplb')
  }
  /**
   * @functionName check
   * @param { Object } params
   * @param { number } params.ok //1-通过|0-不通过
   * @param { String } params.sfzhm //身份证号码，批量通过传数组，不支持批量不通过
   * @param { String } params.scbtgyy //如果不通过，请填写不通过原因
   * @Description 候选人资格审查
   * @return { Object }
   */
  check(params) {
    return tongke.post('/quxian/check/hxr', params)
  }
  /**
   * @functionName toDistribute
   * @param { Object } params
   * @param { Array } params.sfzhm 候选人的身份证号码集合，列表
   * @param { Number } params.fpdw 分配单位，接受分配单位的ID
   * @Description 分配候选人到司法所
   * @return { Object }
   */
  distribute(params) {
    return tongke.post('/quxian/fphxr2sfs', params)
  }
  /**
   * @functionName toDistribute
   * @param { Object } params
   * @param { Boolean } params.unbindall  是否移除全部
   * @param { Array } params.sfzhm 候选人的身份证号码集合，列表
   * @param { Number } params.fpdw 分配单位，接受分配单位的ID
   * @Description 移除候选人，支持全部移除
   * @return { Object }
   */
  remove(params) {
    return tongke.post('/quxian/ycfphxr2sfs', params)
  }
  update(params) {
    return tongke.put(`/quxian/hxr/${params.sfzhm}`, params)
  }
  // 上传图片
  upload(params) {
    return tongke.post('/upload/image', params)
  }
  /**
   * @functionName list_sfs
   * @param { Object }  params
   * @param { Number }  params.page
   * @param { Number }  params.size
   * 非必填
   * @param { Number }  params.bmfs   报名方式  1-随机抽选|2-个人申请|3-组织推荐
   * @param { Number }  params.sczt   审查状态，多个状态用英文逗号隔开
   * @param { Number }  params.keyword  关键词
   * @Description   // 司法所-候选人列表
   * @return { Object }
   */
  list_sfs(params) {
    return tongke.get('/sifasuo/hxr', { params })
  }
  // 司法所-候选人详情-----传入身份证号码
  detail_sfs(sfzhm) {
    return tongke.get(`/sifasuo/hxr/${sfzhm}`)
  }
  create_hxr_sfs(params) {
    return tongke.post('/sifasuo/hxr', params)
  }
  /**
   * @functionName check_sfs
   * @param { Object } params
   * @param { number } params.ok //1-通过|0-不通过
   * @param { String } params.sfzhm //身份证号码，批量通过传数组，不支持批量不通过
   * @param { String } params.scbtgyy //如果不通过，请填写不通过原因
   * @Description 司法所-候选人资格审查
   * @return { Object }
   */
  check_sfs(params) {
    return tongke.post('/sifasuo/check/hxr', params)
  }
  // 司法所-个人信息修改
  update_sfs(params) {
    return tongke.put(`/sifasuo/hxr/${params.sfzhm}`, params)
  }

  /**
   * @functionName list
   * @param { Object }  params
   * @param { Number }  params.page
   * @param { Number }  params.size
   * 非必填
   * @param { Number }  params.jhlx     计划类型
   * @param { Number }  params.jhksnd   年度
   * @param { Number }  params.keyword  关键词
   * @Description   // 候选人资格审查列表
   * @return { Object }
   */
  list_ss(params) {
    return tongke.get('/shengshi/hxr/count', { params })
  }
  /**
   * @functionName list
   * @param { Object }  params
   * @param { Number }  params.page
   * @param { Number }  params.size
   * 非必填
   * @param { Number }  params.bmfs     报名方式
   * @param { Number }  params.xrjh  选任计划id
   * @Description   // 候选人列表
   * @return { Object }
   */
  list_show_ss(params) {
    return tongke.get('/shengshi/hxr/info', { params })
  }
}
export default new Candidate()
